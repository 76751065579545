import { EncryptStorage } from 'encrypt-storage';
import CONFIG from '../CONFIG.json';

// eslint-disable-next-line consistent-return
export const workWithLocalStorage = (action,key,value) => {
    if (window.localStorage) {
        const encryptStorage = new EncryptStorage(CONFIG.secretKey, {});
        let ret = "";
          if(action==='set'){
              encryptStorage.setItem(CONFIG.sufijo+key, JSON.stringify(value));
              ret = "";
          }else if(action==='get'){
            ret = encryptStorage.getItem(CONFIG.sufijo+key)
          }else if(action==='remove'){
              encryptStorage.removeItem(CONFIG.sufijo+key);
              ret = "";
          }
          return ret;
        }
};

export const getUniqueKey = () => `${Date.now()}`;

export const buildBody = (token,obj) =>({
            "data": {
                "API_TOKEN":CONFIG.TOKEN,
                "obj":obj
            }
          })

//

export const isEmpty = (obj) => {
  if(obj === undefined || obj === null){
    return true;
  }
    return Object.keys(obj).length === 0;
  
}

// eslint-disable-next-line arrow-body-style
export const compareArrays = (x,y)=>{
   return x.length === y.length && x.every((o, idx) => compareObjects(o, y[idx]));
}

// eslint-disable-next-line arrow-body-style
export const compareObjects = (x,y)=>{
  return typeof x === 'object' && Object.keys(x).length > 0
        ? Object.keys(x).length === Object.keys(y).length
            && Object.keys(x).every(p => compareObjects(x[p], y[p]))
        : x === y;
}

export const actualizaObjeto = (obj,data) =>{
  const keys = Object.keys(data);
  keys.forEach((key) => {
    obj[key] = data[key]
  });
  return obj
}

export const getProfile = () =>{
  const user= getLoggedInUser()
  const profile = user.token.charAt(0)
  // eslint-disable-next-line radix
  return parseInt(profile)
}

export const getLoggedInUser = () => {
        if(!workWithLocalStorage('get','user','')) return null;
        return workWithLocalStorage('get','user','');
}

export const isNumber = value => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
        return false
    // eslint-disable-next-line no-else-return
    } else {
        return true
    }
}

// is user is logged in
// eslint-disable-next-line arrow-body-style
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

export const dosDecimales = (value) =>{
    const val = `${value}`;
    if(val.includes('.')){
        if(val.indexOf('.') === val.length-2){
            return `${val}0`; 
        }
            return val;
        
    }
        return `${val}.00`;
    
}

export const showLoader = (mostrar) =>{
    if(mostrar)
    {
        document.getElementById('loader').style.display = "block";
    }else{
        document.getElementById('loader').style.display = "none";
    }
}

export const numberToTime = (minutes) =>{
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return `${min}:${(sec < 10 ? "0" : "")}${sec}`;
}

export const compruebaForm = (form,campos,obligatorios) => {
    let valido = true;
    obligatorios.map(o =>{ // eslint-disable-line
      if(campos[o] === form[o]){
        valido = false;
      }
    })
    return valido;
}

export const roundHalf = (num) => Math.round(num*10)/10;

export const limpiaObjeto = (objeto,lista,idcheck) =>{
  const ret = {}
  for (const key in objeto) { // eslint-disable-line
    if(lista[key] !== undefined){ 
      ret[key] = objeto[key];
    }
  }
  if(idcheck && objeto.id !== undefined){
    ret.id = objeto.id
  }
  return ret;
}
