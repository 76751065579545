// component
import Iconify from '../../componentes/Iconify';

import { workWithLocalStorage } from '../../auxiliares/funciones';

import Ficheros from '../../paginas/ficheros';
import Agrupaciones from '../../paginas/agrupaciones';
import Empresas from '../../paginas/empresas';
import Puestos from '../../paginas/puestos';
import Inicios from '../../paginas/inicios';
import Finales from '../../paginas/finales';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const user = workWithLocalStorage('get','usuario',null);

const menuConfig = [
  {
    id: 1,
    title: 'Ficheros',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Ficheros,
    params: {}
  },
  {
    id: 2,
    title: 'Empresas',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Empresas,
    params: {}
  },
  {
    id: 3,
    title: 'Inicios',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Inicios,
    params: {}
  },
  {
    id: 6,
    title: 'Finales',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Finales,
    params: {}
  },
  {
    id: 4,
    title: 'Agrupaciones',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Agrupaciones,
    params: {}
  },
  {
    id: 5,
    title: 'Puestos',
    path: '#',
    perfil: [1],
    icon: getIcon('eva:people-fill'),
    component: Puestos,
    params: {}
  },
];

const menuFilter = () => {
  if(user !== undefined){
    console.log(user)
    // eslint-disable-next-line no-plusplus
    for(let i=menuConfig.length-1; i>=0;i--){
      if(menuConfig[i].children!==undefined){
        // eslint-disable-next-line no-plusplus
        for(let f=menuConfig[i].children.length-1; f>=0;f--){
            if(!menuConfig[i].children[f].perfil.includes(user.idPerfil)){
              menuConfig[i].children.splice(f,1);
            }
        }
      }else if(!menuConfig[i].perfil.includes(user.idPerfil)){
          menuConfig.splice(i,1);
        }
    }
  }
  return menuConfig;
}

const menu  = menuFilter();

export default menu;
