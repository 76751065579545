import { useState } from 'react';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import Iconify from '../Iconify';
import CONFIG from '../../CONFIG.json';
import { workWithLocalStorage, buildBody, isEmpty } from '../../auxiliares/funciones';

// ----------------------------------------------------------------------

export default function LoginForm(props) {

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const FormValues = {
    usuario: '',
    contrasena: '',
  };

  const actualizaCampo = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    FormValues[nombre] = valor
  }

  const onSubmit = async () => {
    setLoading(true);
    // eslint-disable-next-line react/prop-types
    props.setLoader(true);
    const bodyJson = JSON.stringify(buildBody('',FormValues))
    fetch(`${CONFIG.api}login`,{
              method: 'POST',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        if (result.error === 0 && !isEmpty(result.data)) {
           workWithLocalStorage('set','usuario',result.data);
           // eslint-disable-next-line spaced-comment
           window.location.reload();
        } else{          
          // eslint-disable-next-line react/prop-types
          props.setLoader(false);
          Swal.fire({
             title: isEmpty(result.data) ? 'Usuario y/o Contraseña incorrectos!' : result.errorMessage,
             buttons: [{label: 'Ok'}]
           })
           setLoading(false);
        }
    })
  };

  return (
    <form>
      <Stack spacing={3}>
        <TextField name="usuario" label="Usuario" InputLabelProps={{ shrink: true }} onChange={(e)=>actualizaCampo(e)} />

        <TextField
          name="contrasena" InputLabelProps={{ shrink: true }}
          label="Contraseña" onChange={(e)=>actualizaCampo(e)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton onClick={()=>onSubmit()} fullWidth sx={{ my: 2 }} size="large" type="submit" variant="contained" loading={loading}>
        Acceder
      </LoadingButton>
    </form>
  );
}
