import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
// material
import { styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemButton } from '@mui/material';
//
import Iconify from '../../componentes/Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: '15px'
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  setSelected: PropTypes.func,
  selected: PropTypes.number
};

// eslint-disable-next-line react/prop-types
function NavItem({ item, setSelected, selected, setTabs }) {

  const { title, path, info, children, id, component } = item;

  const handleOpen = (id) => {
    setSelected(selected === id ? 0 : id);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: '#d1e9fc'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={()=>handleOpen(id)}
          sx={{
            ...(id === selected && activeRootStyle),
          }}
        >
          {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={id === selected ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, mr: 2 }}
          /> 
        </ListItemStyle>

        <Collapse in={id === selected} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, component } = item;

              return (
                // eslint-disable-next-line react/prop-types
                <ListItemStyle onClick={()=>setTabs({ nombre: title, componente: component, params: {}, visible: true })}
                  key={title}
                  to={path}
                  sx={{ backgroundColor: 'rgba(32, 101, 209, 0.08)', color: '#2065D1; !important', paddingLeft: '15px' }}
                >
                  {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    // eslint-disable-next-line react/prop-types
    <ListItemStyle  onClick={()=>{setSelected(id); setTabs({ nombre: title, componente: component, params: {}, visible: true})}}
      to={path}
      sx={{
        ...(id === selected && activeRootStyle),
      }}
    >
      {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

MenuSection.propTypes = {
  menuConfig: PropTypes.array,
};

// eslint-disable-next-line react/prop-types
export default function MenuSection({ menuConfig, setTabs, ...other }) {  
  const [selected, setSelected] = useState(0);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {menuConfig.map((item, index) => (
          <React.Fragment key={item.id}>
            <NavItem key={item.title} item={item} selected={selected} setSelected={setSelected} setTabs={setTabs} />
            <Divider key={index} light />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}
