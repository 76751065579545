import React from 'react';
// @mui
import { Checkbox , Grid, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------


export default function RTextField({ name, value, label, actualizaCampo, md, ...other }) {
  const [val,setVal] = React.useState(value);
  const sm = md * 2;


  const refreshValue = (e) =>{
      const val = e.target.checked ? 1 : 0;
      setVal(val);
      actualizaCampo({target:{value:val,name:e.target.name}})
  }

  return (
    <Grid item md={md} sm={sm}>
        <FormControlLabel label={label}
            control={
              <Checkbox checked={val === 1} name={name} onChange={(e)=>{refreshValue(e);}} {...other} />
            }/>
    </Grid>
  );
}
