import { useRef, useState } from 'react';
// @mui
import { MenuItem, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
// components
import MenuPopover from '../../componentes/MenuPopover';
import { workWithLocalStorage } from '../../auxiliares/funciones';


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cerrarSesion = () => {
    workWithLocalStorage('remove','usuario',null);
    window.location.reload();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          color: 'red',
          ...(open && {            
            color: 'white',
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '130%',
              height: '130%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: 'red',
            },
          }),
        }}
      >
        <LogoutIcon sx={{ zIndex: 999 }} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          zIndex: 9999,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={()=>cerrarSesion()} sx={{ m: 1 }}>
          Cerrar sesión
        </MenuItem>
      </MenuPopover>
    </>
  );
}
