import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 0,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [detalle, setDetalle] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const [modalOpen, setModalOpen] = React.useState(false);

    const cierraModal = () => setModalOpen(false);
    const abreModal = () => setModalOpen(true);

    const campos = ['agrupacion','fechaInicio','incidencia','procesados','correctos','incorrectos','estado','fechaImportado','campanasH'];
    const cabecera = ['Agrupación','Inicio','Incidencia','Procesados','Correctos','Incorrectos','Estado','Fecha Importado','Grupos Conf.'];
    const camposD = ['af','grupo','incidencia'];
    const cabeceraD = ['Acción','Grupo','Incidencia'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}inicios`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const validaDetalle = (obj) => obj.incorrectos !== 0;

    const Detalle = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {id: obj.id};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}inicio/detalle`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setDetalle(result.data);
            }
            abreModal();
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
        })
    }

    const Relanzar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {id: obj.id};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}inicio/relanzar`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
              let msg ='Fichero relanzado!';
              if (result.error !== 0){
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:'warning',
        nombre:'Detalle',
        icono:<i />,
        funcion: Detalle,
        estilo: {},
        condition: validaDetalle
      },
      {
        color:'success',
        nombre:'Relanzar',
        icono:<i />,
        funcion: Relanzar,
        estilo: {},
        condition: null
      }
    ]

  return (    
    <Page title="Inicios" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Inicios"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <CardHeader title={'Detalle Incidencias'}
                action={<CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>}/>
                <Divider style={{ margin: '15px'}} />
                <CardContent sx={{paddingButtom : 0}}>
                    <RTabla lista={detalle} campos={camposD} cabecera={cabeceraD} acciones={[]}/>
                </CardContent>
            </Card>
        </Modal>
    </Page>
  );
}
