/* eslint-disable react/prop-types */
import * as React from 'react';

export default function Alertas({puesto}) {

    const nombre = puesto.nombre.split('/')[1]

  return (
    <div className="terminal-loader">
        <div className="terminal-header">
            <div className="terminal-title">{nombre} {puesto.firmas === 0 ? ' (Altas)': ' (Firmas)'}</div>
            <div className="terminal-controls">
            { puesto.estado === 'off' && <div className="control close" />}
            { puesto.estado === 'on' &&<div className="control maximize" />}
            </div>
        </div>
        <div className="text">
            Prim. Con: {puesto.primera}<br/>
            Últ. Con: {puesto.conexion}<br/>
            Hace {puesto.tiempo} minutos
        </div>
        <div className="terminal-footer">
            <div className="terminal-title">
                <span style={{color: '#000', fontSize: '14px', fontWeight: 'bold'}}>Incidencias: {puesto.incidencias}</span>
            </div>
            <div className="terminal-controls">
                <span style={{color: '#000', fontSize: '14px', fontWeight: 'bold'}}>Hechos: {puesto.hechos}</span>
            </div>
        </div>
    </div>
  );
}