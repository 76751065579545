/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Box, InputAdornment, TextField, Stack, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { visuallyHidden } from '@mui/utils';

import Iconify from './Iconify';
import Alerta from './alertas';
import { isEmpty } from '../auxiliares/funciones';
import RCheck from './forms/inputs/RCheck';

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },[`&.${tableCellClasses.footer}`]: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        },
    }));

    const MyTableFooter = styled(TableFooter)(({ theme }) => ({
        borderTop: '2px solid',
        borderColor: theme.palette.primary.main,
    }));
  
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));

    function TablePaginationActions(props) {
      const { count, page, rowsPerPage, onPageChange } = props;

      const handleFirstPageButtonClick = () => {
        onPageChange(0);
      };

      const handleBackButtonClick = () => {
        onPageChange(page - 1);
      };

      const handleNextButtonClick = () => {
        onPageChange(page + 1);
      };

      const handleLastPageButtonClick = () => {
        onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };

      return (
        <Box sx={{ flexShrink: 0, ml: 2.5, color: 'white !important' }}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="primera página"
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="página anterior"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="página siguiente"
          >
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="última página"
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      );
    }

    class RTabla extends Component {
        constructor(props) {
            super(props);
            this.state = {
              listado: this.props.lista ? this.props.lista : [],
              listadoO: this.props.lista ? this.props.lista : [],
              cabecera: this.props.cabecera ? this.props.cabecera : [],
              campos: this.props.campos ? this.props.campos : [],
              scroll: this.props.scroll ? this.props.scroll : false,
              check: this.props.check ? this.props.check : false,
              checkId: this.props.checkId ? this.props.checkId : null,
              page:0,
              rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 25,
              order: 'asc',
              orderBy: -1,
              busqueda:this.props.busqText !== undefined ? this.props.busqText : '',
            }
        }

        // eslint-disable-next-line react/no-unused-class-component-methods, react/sort-comp
        handleChangePage = (newPage) => {        
            // eslint-disable-next-line react/no-unused-state
            this.setState({page: newPage});
            console.log(newPage)
        };

        // eslint-disable-next-line react/no-unused-class-component-methods
        handleChangeRowsPerPage = (event) => {
            // eslint-disable-next-line react/no-unused-state
            this.setState({page: 0,rowsPerPage:parseInt(event.target.value, 10) });
        };

        buscaTabla = (input) =>{
            // eslint-disable-next-line react/no-access-state-in-setstate
            const datos = this.state.listadoO.filter(item => this.state.campos.some(key =>
                String(item[key]).toLowerCase().includes(input.target.value.toLowerCase())
              ))
            this.setState({listado: datos, busqueda:input.target.value})
            if(this.props.busqText !== undefined){
              this.props.actualizaBusc(input.target.value)
            }
        }

        descendingComparator = (a, b, orderBy) => {
          const { campos } = this.state
          if (b[campos[orderBy]] < a[campos[orderBy]]) {
            return -1;
          }
          if (b[campos[orderBy]] > a[campos[orderBy]]) {
            return 1;
          }
          return 0;
        }

        getComparator = (order, orderBy) => {
          return order === 'desc'
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
        }

        stableSort = (array, comparator) => {
          const stabilizedThis = array.map((el, index) => [el, index]);
          stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
              return order;
            }
            return a[1] - b[1];
          });
          return stabilizedThis.map((el) => el[0]);
        }

        createSortHandler = (id) => (event) => {
          const { order, orderBy, listado } = this.state
          const isAsc = orderBy === id && order === 'asc';
          const orderA = isAsc ? 'desc' : 'asc';
          this.setState({order: orderA, orderBy: id, listado: this.stableSort(listado, this.getComparator(orderA, id))})
        };

        componentDidMount(){
          if(this.state.scroll){
            window.scrollTo(0,280);
          }
          if(this.props.busqText !== undefined){
            this.buscaTabla({target: {value:this.props.busqText}})
          } 
        }

        componentDidUpdate(){
          if(this.state.scroll){
            // window.scrollTo(0,280);
          }
        }

        render() {
            const { cabecera, campos, listado, listadoO, page, rowsPerPage, order, orderBy, busqueda} = this.state
            const { acciones } = this.props
            return (
                <Container sx={{ maxWidth: '100% !important'}}>
                    <Stack direction="row" sx={{ width: '100%'}} justifyContent="space-between">
                      <TextField placeholder="Buscar en la tabla" value={busqueda} onChange={(e)=>this.buscaTabla(e,'eventos')} type="text" name="buscar" autoComplete="off"
                        sx={{ float: 'right', paddingBottom: '15px'}}
                        InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                                </InputAdornment>
                                ),
                        }}/>
                    </Stack>                
                      {isEmpty(listado) && !isEmpty(listadoO) && 
                      <Stack direction="row" alignItems="right" justifyContent="space-between" mb={5}>
                        <Alerta tipo="warning" titulo="No hay ningun resultado con ese filtro."/>
                      </Stack>}
                      {isEmpty(listado) && isEmpty(listadoO) && 
                      <Stack direction="row" alignItems="right" justifyContent="space-between" mb={5}>
                        <Alerta tipo="info" titulo="La tabla está vacía"/>
                      </Stack>}
                    <Stack direction="row" alignItems="right" justifyContent="space-between" mb={5}>
                      <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {this.state.check && <StyledTableCell align="right">{''}</StyledTableCell>}
                                        {cabecera.map((c,id)=><StyledTableCell key={id} align="right">
                                                                <TableSortLabel
                                                                  active={orderBy === id}
                                                                  direction={orderBy === id ? order : 'asc'}
                                                                  onClick={this.createSortHandler(id)}
                                                                >
                                                                  {c}
                                                                  {orderBy === id ? (
                                                                  <Box component="span" sx={visuallyHidden}>
                                                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </Box>
                                                                  ) : null}
                                                                </TableSortLabel>
                                                              </StyledTableCell>)}
                                        {acciones !== [] &&
                                          <StyledTableCell align="right">{''}</StyledTableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {(rowsPerPage > 0 ? listado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : listado
                                  ).map((g,id) => (
                                        <StyledTableRow key={id}>
                                            {this.state.check && 
                                                <StyledTableCell align="right">
                                                  <RCheck name={g[this.state.checkId]} label="" actualizaCampo={this.props.marcaCheck} value={g.marcado} md={1} />
                                                </StyledTableCell>
                                            }
                                            {campos.map((c, id2)=><StyledTableCell key={id2} align="right">{g[c]}</StyledTableCell>)}
                                            {acciones !== [] &&
                                              <StyledTableCell align="right">
                                                <ButtonGroup sx={{ maxHeight: '36px'}} variant="contained" aria-label="outlined primary button group">                                              
                                                {// eslint-disable-next-line consistent-return
                                                acciones.map((a, i)=>{
                                                    // eslint-disable-next-line no-unused-expressions
                                                    if(a.condition === null || a.condition(g,id)){
                                                      // eslint-disable-next-line no-unused-expressions
                                                      return <Button variant="contained" sx={a.estilo} onClick={()=> {a.funcion !== null ? a.funcion(g) : undefined}} color={a.color} key={i}>{a.icono}{a.nombre}</Button>
                                                    }
                                                  }
                                                )}
                                                </ButtonGroup>
                                              </StyledTableCell>
                                            }
                                        </StyledTableRow>
                                ))}
                                </TableBody>
                                <MyTableFooter>
                                    <TableRow>
                                        <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                        colSpan={8}
                                        count={listado.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelRowsPerPage='Resultados por página'
                                        SelectProps={{
                                            inputProps: {
                                            'aria-label': 'Resultados por página',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={(event)=>this.handleChangeRowsPerPage(event)}
                                        ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </MyTableFooter>
                            </Table>
                      </TableContainer>
                    </Stack>
                  </Container>
            );
        }
    }

export default RTabla;
