import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Grid } from '@mui/material';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import Cpu from '../../componentes/cpu';
// mock
import CONFIG from '../../CONFIG.json';
import {buildBody, isEmpty, workWithLocalStorage, getUniqueKey} from '../../auxiliares/funciones';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    minWidth: '650px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}puestos`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
            setCargado(true);
            setTimeout(()=>{setCargado(false);},6000)
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      // setCargado(false);
      setCargado(false)
    }

    if(isEmpty(lista)){
        return (<Page title="Puestos" key={lista.length} />)
    }

    return (
        <Page title="Puestos" key={lista.length}>
            <Card sx={{maxWidth: '100% !important'}}>
                <CardHeader action={<>
                    <Button variant="contained" color="success" sx={{color: 'white'}} to="#"
                            startIcon={<Iconify icon="eva:refresh-fill"/>}
                            onClick={() => Actualiza()}>
                        Actualizar
                    </Button>
                </>}
                            title="Puestos"
                />
                <Divider style={{margin: '15px'}}/>
                <CardContent>
                    <h5 style={{marginBottom: '15px', marginLeft: '20px'}}>Quedan {lista[0].altas} altas por
                        tratar</h5>
                    <Grid container spacing={1}>
                        {lista.map((l, index) =>
                            <Grid item md={6} lg={6} xl={3}>
                                <Cpu puesto={l} key={index}/>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Page>
    );
}
