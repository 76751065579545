/* eslint-disable react/prop-types */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

export default function Alertas(props) {
  return (
    <Stack sx={{ width: '100%', marginBottom: '15px !important' }} spacing={2}>
      <Alert severity={props.tipo}>
        {props.titulo !== '' && <AlertTitle>{props.titulo}</AlertTitle>}
        {props.texto !== '' && props.texto}
      </Alert>
    </Stack>
  );
}